import { DropdownComponent, DropdownOption, OnChangeEvent } from 'components/dropdown/component'
import * as React from 'react'
import { Badge, Input } from 'reactstrap'
import * as Modals from 'shared/modals'
import { ChatPayload } from 'shared_server_client/types/generative_ai'
import './_styles.scss'
import { BaseGeneratedTextComponent } from './base'
import * as Constants from './constants'
import * as Data from './data'
import * as Types from './types'

interface State extends Types.State {
  articleOptions: DropdownOption[],
  articles: any[],
  prompt: string,
  topics: string[],
}

const emptyState: State = {
  ...Constants.baseEmptyState,
  articleOptions: [],
  articles: [],
  prompt: '',
  topics: [],
}

export class GeneratedTextModalComponent extends BaseGeneratedTextComponent<Modals.ModalComponentProps, State> {

  public static key: string = Constants.TEXT_MODAL_KEY

  private static selectorTypes: Types.SelectorType[] = [
    {
      title: 'Type',
      options: Constants.PromptTypeDropdownOptions,
      key: 'promptType',
    },
    {
      title: 'Length',
      options: Constants.LengthDropdownOptions,
      key: 'length',
    },
    {
      title: 'Tone',
      options: Constants.ToneDropdownOptions,
      key: 'tone',
    },
    {
      title: 'Emojis',
      options: Constants.EmojiDropdownOptions,
      key: 'emoji',
    },
    {
      title: 'Language',
      options: Constants.LanguageDropdownOptions,
      key: 'language',
    },
  ]
  constructor(props: Modals.ModalComponentProps) {
    super(props, GeneratedTextModalComponent.key, GeneratedTextModalComponent.selectorTypes)
    this.state = emptyState
  }

  protected initialize = (activeCommunity): Promise<any> => {
    return Promise.all([
      Data.getBoostedArticles(activeCommunity.communityId),
      Data.getTopics(activeCommunity.communityId),
    ]).then(([boostedArticles, topics]) => {
      if ( topics ) {
        this.setState({
          topics,
        })
      }
      if ( boostedArticles && boostedArticles.length > 0) {
        const articleOptions = boostedArticles.map((a) => ({
          key: a.title,
          value: a.description,
        }))
        this.setState({
          articleOptions,
          articles: boostedArticles,
          content: boostedArticles[0].description,
          title: boostedArticles[0].title,
          url: boostedArticles[0].url,
        })
      }
    })

  }

  protected buildChatPayload(): ChatPayload {
    return {
      ...super.buildChatPayload(),
      topics: this.state.topics,
    }
  }

  protected renderAdditionalInputs = () => {
    return <div>
      <div className="first-row-container">
        <h5>Prompt</h5>
        <Input type="textarea" value={this.state.prompt} onChange={this.setPrompt}
              placeholder="Write some informational content to describe your newsletter"/>
      </div>
      <div className="second-row-container">
        <h5>Your Newsletter Topics</h5>
        <Input max={50} placeholder="Add a Topic (hit enter when done)"
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    const target = event.target as HTMLInputElement
                    this.addTopic(target.value)
                    target.value = ''
                  }
                }}
              />
        <div className="topic-container">
          { this.state.topics.map((topic) => (
          <Badge className="topic" key={topic}>
            <span>{topic}</span>
            <span className="badge-close" onClick={(e) => this.removeTopic(topic)}>x</span>
          </Badge>)) }
        </div>
      </div>
      { (this.state.articleOptions.length > 0) &&
      <div className="first-row-container">
        <h5>Article</h5>
        <DropdownComponent data={this.state.articleOptions}
                           selected={this.state.title}
                           onChange={this.setArticle}/>
      </div>}
    </div>
  }

  // property setters
  private addTopic = (tag: string) => {
    this.setState({
      topics: [...this.state.topics, tag],
    })
  }

  private removeTopic = (tag: string) => {
    this.setState({
      topics: this.state.topics.filter((t) => t !== tag),
    })
  }

  private setArticle = (event: OnChangeEvent) => {
    this.setState({
      content: event.selected.value,
      title: event.selected.key as string,
    })
  }

  private setPrompt = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      prompt: event.target.value,
    })
  }

}
