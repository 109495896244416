import { DropdownOption } from 'components/dropdown/component'

export const TEXT_MODAL_KEY = 'generatedTextModal'
export const SUMMARY_MODAL_KEY = 'generatedSummaryModal'

export const GENERATE_TEXT_ACTION = 'GENERATE_TEXT_ACTION'
export const SAVE_TEXT_ACTION = 'SAVE_TEXT_ACTION'

export const LengthDropdownOptions: DropdownOption[] = [
  {key: 'Short', value: 30},
  {key: 'Medium', value: 75},
  {key: 'Long', value: 125},
  {key: 'Verbose', value: 200},
]

export const LanguageDropdownOptions: DropdownOption[] = [
  {key: 'English', value: 'English'},
  {key: 'French', value: 'French'},
  {key: 'Spanish', value: 'Spanish'},
  {key: 'German', value: 'German'},
  {key: 'Portugese', value: 'Portugese'},
  {key: 'Greek', value: 'Greek'},
]

export const PromptTypeDropdownOptions: DropdownOption[] = [
  {key: 'Introduction', value: 'Introduction'},
  {key: 'Conclusion', value: 'Conclusion'},
  {key: 'Promotional', value: 'Promotion'},
]

export const EmojiDropdownOptions: DropdownOption[] = [
  {key: 'No Emojis', value: 0},
  {key: 'Use Emojis', value: 1},
  {key: 'More Emojis!', value: 2},
]

export const ToneDropdownOptions: DropdownOption[] = [
  { key: 'Friendly', value: 'friendly' },
  { key: 'Serious', value: 'serious' },
  { key: 'Humorous', value: 'humorous'},
  { key: 'Medeival', value: 'medeival' },
  { key: 'Poetic', value: 'poetry' },
]

export const baseEmptyState = {
  content: '',
  emoji: EmojiDropdownOptions[0],
  error: '',
  isSaving: false,
  language: LanguageDropdownOptions[0],
  length: LengthDropdownOptions[0],
  prompt: '',
  promptType: PromptTypeDropdownOptions[0],
  title: '',
  tone: ToneDropdownOptions[0],
  url: '',
  versionDisplay: 0,
  versionGenerate: 0,
  versions: [''],
}
