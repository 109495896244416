import { DropdownComponent, OnChangeEvent } from 'components/dropdown/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { copyToClipboard } from 'generic/utility'
import * as React from 'react'
import { Button, Input, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import * as Modals from 'shared/modals'
import { leadTextAttributes } from 'shared_server_client/constants'
import { ChatPayload, ChatResponse, GenerationType } from 'shared_server_client/types/generative_ai'
import './_styles.scss'
import * as Constants from './constants'
import * as Data from './data'
import * as Types from './types'
import * as Utils from './utils'

declare const RASA_IMAGE_API_ENDPOINT: string

export abstract class BaseGeneratedTextComponent<P extends Modals.ModalComponentProps, S extends Types.State>
  extends Modals.GenericModalComponent<P, S> {

  public static contextType = RasaContext
  public static defaultProps = {
    closeButtonText: 'Copy & Close',
    saveText: 'Insert It!',
    secondAction: Modals.ModalActionType.BUTTON,
  }

  protected communityId: string = ''
  protected sharedStore: SharedStore
  private responseRef
  private selectorPairs: Types.SelectorType[][]

  constructor(props: P, key: string, selectorTypes: Types.SelectorType[]) {
    super(props, key)
    this.responseRef = React.createRef()

    this.selectorPairs = selectorTypes.reduce((acc, selector, index) => {
      if ( index % 2 === 0 ) {
        acc.push([selector])
      } else {
        acc[acc.length - 1].push(selector)
      }
      return acc
    }, [])

  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
    ]).then(([activeCommunity]) => {
      this.communityId = activeCommunity.communityId
      return this.initialize(activeCommunity)
    })
  }

  protected initialize = (activeCommunity): Promise<any> => {
    return Promise.resolve(true)
  }

  // Modal overrides
  protected xButtonClick(data: any) {
    this.clearState()
    super.xButtonClick(data)
  }

  protected close(data: any) {
    this.copyCurrentText()
    this.clearState()
    super.close(data)
  }

  protected save(data: any) {
    this.clearState()
    this.saveEvent(Utils.saveTextEvent(this.key, 'insert', this.state))
    this.props.saveModal(this.key, {
      ...data,
      [leadTextAttributes.text]: Utils.plain2html(this.getCurrentText()),
    })
  }

  protected clearState = () => {
    this.setState({
      ...Constants.baseEmptyState,
    })
  }

  protected getTitleText(data: any): string {
    return `<img height="50px" src=${RASA_IMAGE_API_ENDPOINT}/hosted-images-newsbrief/path/rasa/beta-icon.jpg?h=100&w=100/>
      Beta Feature&nbsp;&nbsp;
      <a href="https://rasa.io/pushing-send/ai-generative-text/" target="_blank" rel="noopener noreferrer">Learn more</a>`
  }

  protected renderSelector = (selector: Types.SelectorType, className: string) => {
    if ( selector ) {
      return <div className={className}>
          <h5>{selector.title}</h5>
          <DropdownComponent data={selector.options}
                            selected={this.state[selector.key].key}
                            onChange={(e) => this.setSelectorType(e, selector)}/>
      </div>
    } else {
      return <div className={className}/>
    }
  }

  protected renderAdditionalInputs = () => {
    return null
  }

  protected renderInitialInputs = () => {
    return null
  }

  // Component overrides - render the modal
  protected renderChildren(data: any) {

    return <div className="ai-generate body-container generated-text-modal-body">
      <div className="left-side-container">
        {this.renderInitialInputs()}
        {this.selectorPairs.map((pair) => {
          return <div className="first-row-container flex-container">
            {this.renderSelector(pair[0], 'left-side-container')}
            {this.renderSelector(pair[1], 'right-side-container')}
          </div>
        })}
        {this.renderAdditionalInputs()}
      </div>
      <div className="right-side-container full-height">
        <div className="first-row-container">
          <h5>{this.responsesTitle()}</h5>
          <Nav tabs>
            { this.state.versions.map((message, index) => (
            <NavItem key={index}>
              <NavLink className={this.state.versionDisplay === index ? 'active' : ''}
                       onClick={() => this.setVersion(index)}>
                <span>
                  {(this.state.isSaving && this.state.versionGenerate === index) ? '* ' : ''}Version {index + 1}
                </span>
              </NavLink>
            </NavItem>
            ))}
          </Nav>
          <TabContent>
            <TabPane>
              <Input className="response-text" type="textarea" id="response-text"
                     ref={this.responseRef}
                     value={this.state.versions[this.state.versionDisplay]}
                     onFocus={(e) => e.target.select()}
                     onChange={this.setMessage}/>
            </TabPane>
            <div className="actions flex-centered">
                <Button disabled={!this.hasCurrentVersion()} onClick={this.copyCurrentText}>
                  <span>Copy</span>
                </Button>
                <Button disabled={this.state.isSaving} onClick={this.generateText}>
                  <span>Generate</span>
                </Button>
                <Button disabled={!this.hasCurrentVersion()} onClick={this.newVersion}>
                  <span>New</span>
                </Button>
            </div>
          </TabContent>
        </div>
        { this.state.isSaving && <div className="second-row-container">
          <Loading size="32"></Loading>
        </div>}
        { this.state.error && <div className="second-row-container">
          <span className="error">{this.state.error}</span>
        </div>}
      </div>
    </div>
  }

  protected responsesTitle = (): string => 'Responses'

  // Modal overrides
  protected saveDisabled(data: any): boolean {
    return !this.hasCurrentVersion()
  }

  protected secondActionDisabled(data: any): boolean {
    return !this.hasCurrentVersion()
  }

  protected getGenerationType(): GenerationType {
    return GenerationType.TEXT
  }

  protected buildChatPayload(): ChatPayload {
    return {
      content: this.state.content,
      emojis: this.state.emoji.value,
      language: this.state.language.value,
      length: this.state.length.value,
      prompt: this.state.prompt,
      promptType: this.state.promptType.value,
      topics: [],
      title: this.state.title,
      tone: this.state.tone.value,
      url: '',
    }
  }

  private setMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      versions: this.state.versions.map((message, index) => {
        if ( index === this.state.versionDisplay ) {
          return event.target.value
        } else {
          return message
        }
      }),
    })
  }

  private setVersion = (version: number) => {
    this.setState({
      versionDisplay: version,
    })
  }

  private setSelectorType = (event: OnChangeEvent, selector: Types.SelectorType) => {
    this.setState({
      ...this.state,
      [selector.key]: event.selected,
    })
  }

  private updateChatMessage: Data.ChatCallback = (response: ChatResponse) => {
    if ( !response ) {
      this.setState({
        isSaving: false,
      })
    } else {
      if ( response.done || response.error ) {
        this.setState({
          isSaving: false,
          error: response.error,
        })
      }
      if ( response.message ) {
        this.setState({
          versions: this.state.versions.map((message, index) => {
            if ( index === this.state.versionGenerate ) {
              return message + response.message
            } else {
              return message
            }
          }),
        })
      }
    }
  }

  private getCurrentText = (): string => {
    return this.state.versions[this.state.versionDisplay]
  }

  private hasCurrentVersion(): boolean {
    return !this.state.isSaving && this.getCurrentText().length > 0
  }

  private copyCurrentText = (): void => {
    this.saveEvent(Utils.saveTextEvent(this.key, 'copy', this.state))
    copyToClipboard(this.getCurrentText())

    // YUCK.  In order to select the text, I need the DOM element.  But react REF
    // only gives me a React element.  focus doesnt' invoke the onFocus method automatically.
    // So this hack - call the react focus, then get the DOM element and pass it to the onFocus
    // method directly.
    this.responseRef.current.focus()
    this.responseRef.current.props.onFocus({target: document.getElementById('response-text')})
  }

  private newVersion = (): void => {
    // For now, overriding save to mean "open a new tab"
    this.setState({
      versions: this.state.versions.concat(['']),
      versionDisplay: this.state.versions.length,
      versionGenerate: this.state.versions.length,
    })
  }

  private generateText = () => {
    this.saveEvent(Utils.generateTextEvent(this.key, this.state))
    this.setState({
      isSaving: true,
      error: '',
      versionGenerate: this.state.versionDisplay,
      versions: this.state.versions.map((message, index) => {
        if ( index === this.state.versionDisplay ) {
          return ''
        } else {
          return message
        }
      }),
    }, () => {
      Data.streamChatResponse(
        this.communityId,
        this.buildChatPayload(),
        this.getGenerationType(),
        this.updateChatMessage)
    })
  }

  private saveEvent = (event) => this.context.store.dispatch(event)

}
