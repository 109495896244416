import bodybuilder from 'bodybuilder'
import * as Validation from 'components/validation/validations'
import {
  AggregationType,
  DateRanges,
  FilterType,
  IndexName,
  toFilter,
} from 'elasticsearch/constants'
import { AjaxStreamCallback, AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Dataset } from 'generic/dataset'
import { isDevelopment } from 'generic/utility';
import {
  AnimatePayload,
  AnimateResponse,
  ChatPayload,
  ChatResponse,
  GenerationType,
 } from 'shared_server_client/types/generative_ai'

export const getTopics = (communityId: string) => {
  const url: string = `${AjaxWrapper.getServerUrl()}/dataset/${communityId}/elasticsearch?index=${IndexName.TAGS}`
  const  search = bodybuilder().size(0).filter(
    FilterType.range,
    'message_send_date',
    toFilter(DateRanges.LastMonth),
  ).aggregation(AggregationType.terms, 'text.keyword', {size: 25}, 'tags')

  const payload: any = search.build()
  return AjaxWrapper.ajax(
    url,
    HttpMethod.POST,
    {
      ...payload,
      report_name: 'topics',
    },
  ).then((topicsResponse) => {
    const topicBuckets = topicsResponse.aggregations.tags.buckets
    if (topicBuckets) {
      const topicSet: Set<string> = new Set(
        topicBuckets.map((bucket) => bucket.key.toLowerCase().replace('-', '')))
      const topicList = Array.from(topicSet).sort(() => Math.random() - 0.5)
      if ( topicList.length === 0 && isDevelopment() ) {
        return ['running', 'outdoors', 'ultramarathon']
      }
      return topicList.slice(0, Math.min(5, topicList.length))
    } else {
      return []
    }
  })
}

export const getBoostedArticles = (communityId: string) => {
  return new Dataset().loadCommunityDataset('communityUpcomingArticles', communityId, [
    { param: 'minimum_boost', value: '1' },
    { param: 'isActive', value: '1' }
  ]).then((upcomingArticlesResponse) => {
    if ( upcomingArticlesResponse[0].length > 0 ) {
      return upcomingArticlesResponse[0]
    } else {
      return []
    }
  })
}

export const getAnimatedGif = (communityId: string, payload: AnimatePayload): Promise<AnimateResponse> => {
  const videoUrl: string = `${AjaxWrapper.getServerUrl()}/ai/${communityId}/animate-video`
  const validVideoUrl = Validation.validateUrl(payload.videoUrl).valid
  if ( !validVideoUrl ) {
    return Promise.resolve({
      imageUrl: '',
    })
  }
  return AjaxWrapper.ajax(videoUrl, HttpMethod.POST, payload)
}

export type ChatCallback = (response: ChatResponse) => void

export const streamChatResponse = (
  communityId: string,
  payload: ChatPayload,
  generationType: GenerationType,
  callback: ChatCallback) => {
  const textUrl: string =
    `${AjaxWrapper.getServerUrl()}/ai/${communityId}/stream/${generationType}`
  const responseParser: AjaxStreamCallback = (success: boolean, chunk: string) => {
    if ( !chunk ) {
      callback(null)
    } else if ( !success ) {
      callback({
        done: true,
        error: chunk,
        message: chunk,
      })
    } else {
      const jsonString: string = '[' + chunk.replace(/}{/g, '},{') + ']'
      try {
        JSON.parse(jsonString).forEach((r: ChatResponse) => {
          callback(r)
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('ERROR parsing ' + jsonString)
      }
    }
  }
  AjaxWrapper.stream(textUrl, HttpMethod.POST, { ...payload, stream: true}, responseParser)
}

export const getChatResponse = (communityId: string, payload: ChatPayload): Promise<ChatResponse> => {
  const textUrl: string = `${AjaxWrapper.getServerUrl()}/ai/${communityId}/generate-text`
  return AjaxWrapper.ajax(textUrl, HttpMethod.POST, payload)

}
