//// GENERIC NavigatorBase class for UX
//// Properties:
////  Sections: PanelBarOption[] - sets up the various sections, refer to info on PanelBarOption class
////  BaseRoute: string - the base route to build up from, the routes from the sections will be added to this
////  Title: string - title to put into the panel bar
////  SubTitle: string - sub-title to put into the panel bar
////  HeaderIcon: React.Component - optional component to display in the header of the panel bar
////  ItemIcon: React.Component - optional component to display in the panel bar for each item
////  HeaderCssClass: string - CSS class to style the wrapper of the navigator component
////  ContentDivCssClass: string - CSS class to style the div that contains the components for each section
////  onChange: optional event handler for containers of this component to listen for when nav changes
import { RasaContext } from 'context'
import React from 'react'
import {connect} from 'react-redux'
import * as Router from 'router'

import { ACCOUNT_DUNNING } from '../common/constants'
import { DashboardMenuOption } from '../dashboard-menu/constants'
import { DesktopPanelBar } from '../panel-bar/desktop'
import { PanelBarOption } from '../panel-bar/types'
import { GenericNavigatorComponent } from './component'

import { Roles } from '../../shared/constants'
import './styles.css'

class DesktopNavigatorComponent extends GenericNavigatorComponent {
  public static contextType = RasaContext
  public render() {
    this.clearChildren()
    return <div className={this.getSuspendedClass()}>
              <div className={this.blankIfNull(this.props.headerCssClass) + ' generic-navigator-wrap'}>
                <DesktopPanelBar
                  onChange={(e: PanelBarOption) => {this.handleNav(e)}}
                  itemList={this.props.sections}
                  selectedItem={this.props.selectedItem}
                  title = {this.props.title}
                  subTitle = {this.props.subTitle}
                  headerIcon = {this.props.headerIcon || ''}
                />
                <div className={this.blankIfNull(this.props.contentDivCssClass) + ' generic-navigator-contentpane clearfix'}>
                  {
                    this.props.sections.map((item) => this.shouldShowComponent(item) &&
                      <div key={item.name}>
                        {this.saveChild(React.createElement(item.component, {}))}
                      </div>)
                  }
                </div>
              </div>
            </div>
  }

  private getSuspendedClass() {
    if (this.context.user.activeCommunity && this.context.user.activeCommunity.data &&
      this.context.user.activeCommunity.data.community_account_suspended === 1) {
      if (this.context.user.activeCommunity.data.community_account_suspend_reason !== ACCOUNT_DUNNING
        && window.location.pathname !== DashboardMenuOption.billing
        && this.context.user.activeCommunity.role !== Roles.super_admin
      ) {
        return 'account-suspended'
      }
    }
    return ''
  }
}

export const DesktopNavigator = connect(
  null,
  {
    push: Router.push,
  },
  )(DesktopNavigatorComponent)
